const color = {
  white: '#fff',
  whiteOpacity: 'rgba(255, 255, 255, 0.7)',
  brack000: '#000',
  brackOpacity: 'rgba(0,0,0,0.4)',
  brack111: '#111',
  brack212: '#212121',
  brack333: '#333',
  brack888: '#888',
  grayD3: '#ddd',
  grayfF7: '#F7F7F7',
  grayfF8: '#f8f8f8',
  grayfE6: '#e6e6e6',
  grayA1: '#A1A1A1',
  gray8b: '#8b8b8b',
  grayFA: '#FAFAFA',
  grayF5: '#F5F5F5',
  gray4E: '#4E5666',
  gray76: '#767676',
  grayF0: '#F0F0F0',

  blue1: '#EBF4FD',
  blue2: '#2A91D1',
  blue3: '#307FE2',
  blue4: '#BDD8F4',
  blue5: '#CFDDED',
  blue6: '#EEF5FD',
  blue7: '#cfddec',
  blue8: '#80a9cf',
  blue9: '#ECF2F5',
  blue10: '#0051A4',
  blue11: '#ECF8FF',
  blue12: '#1AA2EE',

  green: '#0BA2A2',
  green2: '#ECF8F8',

  orange1: '#DD8A44',
  orange2: '#FF7F32',
  orange3: '#FFF9F3',
  orange4: '#DB8A4B',
  orange5: '#FFF4EE',
  orange6: '#FFA300',
  orange7: '#FFF2EC',
  orange8: '#FF7276',
  orange9: '#FFA572',

  pink1: '#FF7779',
  pink2: '#FFF3F0',
  pink3: '#E9837B',

  red1: '#E4002B',
  red2: '#FFF4F5',

  yellow: '#FBFFE8'
} as const

export default color
