import React from 'react'
import { css } from '@emotion/react'
import color from '@/components/lp/scalehack/color'
import { mq } from '@/components/media/media'
import { cssServiceButton } from '@/components/scalehack/scalehackForm'
import { shs } from '@/components/lp/scalehack/for-sales/theme'

export const Complete: React.VFC= () => {
  return (
    <>
      <main>
        <section css={cssSection}>
          <h1 css={cssHeading}>送信が完了しました</h1>
          <p css={cssText}>
            お問い合わせいただき、誠にありがとうございます。
            <br />
            3営業日以内を目処にご記載の連絡先へご連絡いたします。
          </p>
          <a css={[cssServiceButton, buttonColor]} href="/group/scalehack/for-sales">
            トップページへ
          </a>
        </section>
      </main>
    </>
  )
}

// Section
const cssSection = css`
  width: 100%;
  max-width: 632px;
  padding: 60px 16px 205px;
  margin: 0 auto;
  color: ${color.brack111};

  ${mq.pc} {
    padding: 105px 16px 337px;
  }
`

// Heading
const cssHeading = css`
  padding-bottom: 24px;
  font-size: 1.8rem;
  font-weight: 700;
  text-align: center;
  letter-spacing: 1.8px;

  ${mq.pc} {
    padding-bottom: 32px;
    font-size: 2.8rem;
    font-weight: 300;
    letter-spacing: 2.8px;
  }
`

// Text
const cssText = css`
  padding-bottom: 48px;
  font-size: 1.4rem;
  line-height: 1.65;
  text-align: center;
  letter-spacing: 0.28px;

  ${mq.pc} {
    padding-bottom: 56px;
    font-size: 1.8rem;
    letter-spacing: 1.8px;
  }
`
const buttonColor = css`
  color: ${color.white};
  background-color: ${shs.color.primaryBlue};
`
