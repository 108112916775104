import React from 'react'

import { Head } from '@/components/lp/scalehack/for-sales/head'
import { Global } from '@emotion/react'
import { Layout } from '@/components/lp/scalehack/for-sales/layout'
import { companyNavListData } from '@/constants/scalehack/for-sales/header'
import { Complete } from '@/components/lp/scalehack/for-sales/complete'
import { cssGlobalStyeShs } from '@/pages/group/scalehack/for-sales/'
import { companyAnchorNavList } from '@/constants/scalehack/for-sales/footer'

export default () => {
  return (
    <>
      <Head
        title="完了| テックセールス特化型セールスシェアリングプラットフォーム"
        url="/group/scalehack/for-sales/contact/complete"
      />
      <Global styles={cssGlobalStyeShs} />
      <Layout navList={companyNavListData} footerAnchorNavList={companyAnchorNavList}>
        <Complete />
      </Layout>
    </>
  )
}
